import { Component, OnInit } from '@angular/core';
import{EnService} from './../services/data/en.service';
import{GeService} from './../services/data/ge.service';
@Component({
  selector: 'app-branding',
  templateUrl: './branding.component.html',
  styleUrls: ['./branding.component.css']
})
export class BrandingComponent implements OnInit {
  settings :any={imagealign:"right",isfirst:true};
  settings2 :any={imagealign:"right",isfirst:false,Iscol:"no",d_mainrow:{d_class:'row',d_col:[{d_class:'col-12 col-md-8'},{d_class:'col-12 col-md-4 d-none d-sm-block d-lg-block'}]}};
  settings3 :any={imagealign:"left",isfirst:false,Iscol:"no",d_mainrow:{d_class:'row row-eq-height',d_col:[{d_class:'col-12 col-md-4 text-right'},{d_class:'col-12 col-md-8'}]}};
  settings4 :any={imagealign:"",isfirst:false,Iscol:"no",d_mainrow:{d_class:'row row-eq-height',d_col:[{d_class:'col-12 col-md-12'}]}};

  Page_content :any;
  //constructor(private en :EnService)
  constructor(private ge :GeService)
  {
  }

  ngOnInit(): void {
   // this.Page_content =this.en.get().Branding;
    this.Page_content =this.ge.get().Branding;
    window.scrollTo(0, 0);
  }

}
