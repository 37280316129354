<header>
    <app-header-top></app-header-top>
    <app-head></app-head>
    </header>
    <app-full-width-holder [modulesettings]="settings" [content]="Page_content.BlockSection"></app-full-width-holder>
  
    <app-infoblock-holder [col]="4" [content]="Page_content.Infoblock"></app-infoblock-holder>
    
    <div class="service-section">
      <div class="container-xl">
        <div class="row">

          <div class="col-12 col-sm-12 col-lg-12">
            <h3 class="heading-text text-blue text-uppercase pb-3">Wir bieten massgeschneiderte Pakete für jedes Unternehmen</h3>
          </div>
          
          <div class="col-12 col-sm-6 col-lg-4 my-5">
            <div class="seller-box blue-bg">
              <p class="subtitle text-uppercase text-left text-blue font-weight-bold mt-4">Webseite S von SwissWebArt</p>
              <p class="service-subtitle text-left mt-2">Kostengünstig und unkompliziert für einen zeitgemässen Auftritt</p>
              <p class="subtitle text-uppercase text-left text-blue font-weight-bolder">CHF 1950.-</p>
              <p class="service-subtitle text-left text-blue mt-2">Einmalige Erstellungsgebühr.</p>
              <p>Ideal für Unternehmen, die eine einfache, aber wirkungsvolle Website wünschen</p>
              <ul class="m-0 pl-3 pb-3">
                <li>Enthält eine Inhaltsseite (keine zusätzlichen Seiten verfügbar)</li>
              </ul>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <a _ngcontent-yge-c140="" href="/Kontakt" class="ctn-btn btn btn-blue btn-block btn-send px-4 text-left"><small _ngcontent-yge-c140="">Vereinbaren Sie einen Beratungstermin</small><span _ngcontent-yge-c140="" class="small-icon float-right"><i _ngcontent-yge-c140="" class="fa fa-arrow-right"></i></span></a>
                
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-4 my-5">
            <div class="seller-box bestseller-box red-bg">
              <!--<p class="text-left text-read mb-1">BESTSELLER</p> -->
              <p class="subtitle text-uppercase text-left text-blue font-weight-bold mt-4">Webseite M von SwissWebArt</p>
              <!-- <p class="service-subtitle text-left mt-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p> -->
              <p class="subtitle text-uppercase text-left text-blue font-weight-bolder mt-5 pt-4">CHF 4390.-</p>
              <p class="service-subtitle text-left text-blue mt-2">Einmalige Erstellungsgebühr.</p>
              <p>Ideal für Unternehmen, die eine leistungsstarke und dynamische Website benötigen</p>
              <ul class="m-0 pl-3 pb-3">
                <li>Enthält bis zu 5 Inhaltsseiten (mit der Option auf bis zu 5 zusätzliche Seiten)</li>
              </ul>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <a _ngcontent-yge-c140="" href="/Kontakt" class="ctn-btn btn btn-blue btn-block btn-send px-4 text-left"><small _ngcontent-yge-c140="">Vereinbaren Sie einen Beratungstermin</small><span _ngcontent-yge-c140="" class="small-icon float-right"><i _ngcontent-yge-c140="" class="fa fa-arrow-right"></i></span></a>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-4 my-5">
            <div class="seller-box blue-bg">
              <p class="subtitle text-uppercase text-left text-blue font-weight-bold mt-4">Webseite L von SwissWebArt</p>
              <!-- <p class="service-subtitle text-left mt-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> -->
              <p class="subtitle text-uppercase text-left text-blue font-weight-bolder mt-5 pt-4">CHF 8490.-</p>
              <p class="service-subtitle text-left text-blue mt-2">Einmalige Erstellungsgebühr.</p>
              <p>Ideal für Unternehmen, die mit einer individuell gestalteten Website und exklusiven Funktionen beeindrucken möchten</p>
              <ul class="m-0 pl-3 pb-3">
                <li>Beim Webseiten-Angebot L erhalten Sie ein massgeschneidertes Design und bis zu 10 Unterseiten, mit der Möglichkeit, auf bis zu 20 Unterseiten zu erweitern.</li>
              </ul>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <a _ngcontent-yge-c140="" href="/Kontakt" class="ctn-btn btn btn-blue btn-block btn-send px-4 text-left"><small _ngcontent-yge-c140="">Vereinbaren Sie einen Beratungstermin</small><span _ngcontent-yge-c140="" class="small-icon float-right"><i _ngcontent-yge-c140="" class="fa fa-arrow-right"></i></span></a>
                
            </div>
          </div>
        
        </div>
      </div>
    </div>

    <app-full-width-holder [modulesettings]="settings2"  [content]="Page_content.BlockSection2"></app-full-width-holder>
    <app-full-width-holder [modulesettings]="settings3"  [content]="Page_content.BlockSection3"></app-full-width-holder>
    <app-full-width-holder [modulesettings]="settings4"  [content]="Page_content.BlockSection4"></app-full-width-holder>
    <app-full-width-holder [modulesettings]="settings5"  [content]="Page_content.BlockSection5"></app-full-width-holder>
    <app-full-width-holder [modulesettings]="settings6"  [content]="Page_content.BlockSection6"></app-full-width-holder>
    <app-full-width-holder [modulesettings]="settings7"  [content]="Page_content.BlockSection1"></app-full-width-holder>
      <!-- Footer Start -->
    <footer>
    <app-contact-block></app-contact-block>
    <app-footer></app-footer>
    </footer><!-- Footer End -->
