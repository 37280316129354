import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Appointment, GeneralForm } from 'src/app/models/contactModel';
import { FormService } from 'src/app/services/data/FormPost/forms.service';
import{EnService} from  '../../services/data/en.service';
import{GeService} from '../../services/data/ge.service';
@Component({
  selector: 'app-contact-block',
  templateUrl: './contact-block.component.html',
  styleUrls: ['./contact-block.component.css']
})
export class ContactBlockComponent implements OnInit {
  public isCollapsedfst = false;
  public isCollapsednd = true;
  Page_content :any;

  @ViewChild('generalForm')
  generalForm!: NgForm;
  @ViewChild('appointmentForm')
  appointmentForm!: NgForm;

  genFormPost:GeneralForm;
  appointmentPost:Appointment;
  
  constructor(private ge :GeService,
    private form:FormService,
    private toastr: ToastrService,private router: Router,
    ) {
    this.genFormPost=new GeneralForm();
    this.appointmentPost=new Appointment();
   }

  ngOnInit(): void {
    this.Page_content =this.ge.get().Contact;
  }
  generalFormsubmit(){
    if (this.generalForm.invalid) {
      this.generalForm.form.markAllAsTouched();
      console.log('invalid');
      //this.scrollTop();
    }
    else {
      console.log('General Form',this.genFormPost);
      this.form.genralFormPost(this.genFormPost).subscribe(q=>{
        console.log('sendMail',q);
        this.toastr.success("Message Sent Successfully","SUCCESS");
        this.reloadCurrentRoute();
      },err=>{
        this.toastr.error("Oop! Something went wrong","ERROR");
      });
    }


  }
  appFormsubmit(){
    if (this.appointmentForm.invalid) {
      this.appointmentForm.form.markAllAsTouched();
      console.log('invalid');
      //this.scrollTop();
    }
    else {
      console.log('AppointmentPost',this.appointmentPost);
      this.form.AppointmentformPost(this.appointmentPost).subscribe(q=>{
        console.log('sendMail',q);
        this.toastr.success("Appointment Booked Successfully","SUCCESS");
        this.reloadCurrentRoute();
      },err=>{
        this.toastr.error("Oop! Something went wrong","ERROR");
      });
    }
  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
}
