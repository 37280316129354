import { Component, OnInit } from '@angular/core';
import{GeService} from './../services/data/ge.service';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.css']
})
export class ImprintComponent implements OnInit {
  Page_content :any;
  constructor(private ge :GeService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

}
