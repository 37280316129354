<header>
    <app-header-top></app-header-top>
    <app-head></app-head>
</header>
<div class="common-section firstblock">
    <div class="container-xl">
        <div class="row">
            <div class="col-md-12">
                <!--<h2>Datenschutzerklärung</h2>
                <p>Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der
                    EU-Datenschutzgrundverordnung (DSGVO), ist:</p>
                <p>&nbsp;</p>
                <p>Trade 365 GmbH<br />
                    Max-Högger-Strasse 6<br />
                    8048 Zürich</p>
                <p>Telefon: +41442443223<br />
                    E-Mail: info@trade365.ch<br />
                    WebSite: https://www.webtrade365.ch/</p>
                <p>&nbsp;</p>
                <h2>Allgemeiner Hinweis</h2>
                <p>Gestützt auf Artikel 13 der schweizerischen Bundesverfassung und die datenschutzrechtlichen
                    Bestimmungen des Bundes (Datenschutzgesetz, DSG) hat jede Person Anspruch auf Schutz ihrer
                    Privatsphäre sowie auf Schutz vor Missbrauch ihrer persönlichen Daten. Die Betreiber dieser Seiten
                    nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
                    vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
                    Datenschutzerklärung.</p>
                <p>In Zusammenarbeit mit unseren Hosting-Providern bemühen wir uns, die Datenbanken so gut wie möglich
                    vor fremden Zugriffen, Verlusten, Missbrauch oder vor Fälschung zu schützen.</p>
                <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
                    Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
                    nicht möglich.</p>
                <p>Durch die Nutzung dieser WebSite erklären Sie sich mit der Erhebung, Verarbeitung und Nutzung von
                    Daten gemäss der nachfolgenden Beschreibung einverstanden. Diese WebSite kann grundsätzlich ohne
                    Registrierung besucht werden. Dabei werden Daten wie beispielsweise aufgerufene Seiten bzw. Namen
                    der abgerufenen Datei, Datum und Uhrzeit zu statistischen Zwecken auf dem Server gespeichert, ohne
                    dass diese Daten unmittelbar auf Ihre Person bezogen werden. Personenbezogene Daten, insbesondere
                    Name, Adresse oder E-Mail-Adresse werden soweit möglich auf freiwilliger Basis erhoben. Ohne Ihre
                    Einwilligung erfolgt keine Weitergabe der Daten an Dritte.</p>
                <p>&nbsp;</p>
                <h2>Datenschutzerklärung für Cookies</h2>
                <p>Diese WebSite verwendet Cookies. Das sind kleine Textdateien, die es möglich machen, auf dem Endgerät
                    des Nutzers spezifische, auf den Nutzer bezogene Informationen zu speichern, während er die Website
                    nutzt. Cookies ermöglichen es, insbesondere Nutzungshäufigkeit und Nutzeranzahl der Seiten zu
                    ermitteln, Verhaltensweisen der Seitennutzung zu analysieren, aber auch unser Angebot
                    kundenfreundlicher zu gestalten.&nbsp;Cookies bleiben über das Ende einer Browser-Sitzung
                    gespeichert und können bei einem erneuten Seitenbesuch wieder aufgerufen werden. Wenn Sie das nicht
                    wünschen, sollten Sie Ihren Internetbrowser so einstellen, dass er die Annahme von Cookies
                    verweigert.</p>
                <p>Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des Onlinemarketing eingesetzten Cookies
                    kann bei einer Vielzahl der Dienste, vor allem im Fall des Trackings, über die US-amerikanische
                    Seite&nbsp;<a href="http://www.aboutads.info/choices/" target="_blank"
                        rel="noopener noreferrer">http://www.aboutads.info/choices/</a>&nbsp;oder die EU-Seite&nbsp;<a
                        href="http://www.youronlinechoices.com/" target="_blank"
                        rel="noopener noreferrer">http://www.youronlinechoices.com/</a>&nbsp;erklärt werden. Des
                    Weiteren kann die Speicherung von Cookies mittels deren Abschaltung in den Einstellungen des
                    Browsers erreicht werden. Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen dieses
                    Onlineangebotes genutzt werden können.</p>
                <p>&nbsp;</p>
                <h2>Datenschutzerklärung für Kontaktformular</h2>
                <p>Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem
                    Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage
                    und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre
                    Einwilligung weiter.</p>
                <p>&nbsp;</p>
                <h2>Änderungen</h2>
                <p>Wir können diese Datenschutzerklärung jederzeit ohne Vorankündigung anpassen. Es gilt die jeweils
                    aktuelle, auf unserer Website publizierte Fassung. Soweit die Datenschutzerklärung Teil einer
                    Vereinbarung mit Ihnen ist, werden wir Sie im Falle einer Aktualisierung über die Änderung per
                    E-Mail oder auf andere geeignete Weise informieren.</p>
                <p>&nbsp;</p>
                <h2>Fragen an den Datenschutzbeauftragten</h2>
                <p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich
                    direkt an die für den Datenschutz zu Beginn der Datenschutzerklärung aufgeführten, verantwortlichen
                    Person in unserer Organisation.</p>
                <p>&nbsp;</p>
                <p>Zürich, 10.10.2019<br />
                    Quelle:&nbsp;<a href="https://www.swissanwalt.ch/" target="_blank"
                        rel="noopener noreferrer">SwissAnwalt</a></p> -->
                
                <h2>Datenschutzerklärung</h2>
                <p>&nbsp;</p>
                <h2>1. Datenschutz auf einen Blick</h2>
                <h4>Allgemeine Hinweise</h4>
                <p>Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.</p>
                <p>&nbsp;</p>                
                <h4>Datenerfassung auf unserer Website</h4>
                <p><b>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</b></p>
                <p>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser Website entnehmen.</p>
                <p><b>Wie erfassen wir Ihre Daten?</b></p>
                <p>Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Kontaktformular eingeben.</p>
                <p>Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website betreten.</p>
                <p><b>Wofür nutzen wir Ihre Daten?</b></p>
                <p>Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.</p>
                <p><b>Welche Rechte haben Sie bezüglich Ihrer Daten?</b></p>
                <p>Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben ausserdem ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.</p>
                <p>&nbsp;</p>
                <h2>2. Allgemeine Hinweise und Pflichtinformationen</h2>
                <p><b>Datenschutz</b></p>
                <p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.</p>
                <p>Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.</p>
                <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.</p>
                <p>&nbsp;</p>
                <h2>3. Hinweis zur verantwortlichen Stelle</h2>
                <p><b>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</b></p>
                <p>Swisswebart<br />
                    Trade 365 GmbH<br />
                    Max-Högger-Strasse 6<br />
                    8048 Zürich</p>
                <p>Telefon: +41442443223<br />
                    E-Mail: info@swisswebart.ch</p>
                <p>Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.) entscheidet.</p>
                <p>&nbsp;</p>
                <h2>4. Datenschutzbeauftragter</h2>
                <p>Wir haben keinen Datenschutzbeauftragten bestellt, da wir gemäss DSGVO nicht verpflichtet sind, einen Datenschutzbeauftragten zu bestellen.</p>
                <p>&nbsp;</p>
                <h2>5. Widerruf Ihrer Einwilligung zur Datenverarbeitung</h2>
                <p>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmässigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</p>
                <p>&nbsp;</p>
                <h2>6. Beschwerderecht bei der zuständigen Aufsichtsbehörde</h2>
                <p>Im Falle datenschutzrechtlicher Verstösse steht dem Betroffenen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen seinen Sitz hat. Eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten können folgendem Link entnommen werden: [Link zur Datenschutzbehörde].</p>
                <p>&nbsp;</p>
                <h2>7. Recht auf Datenübertragbarkeit</h2>
                <p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>
                <p>&nbsp;</p>
                <h2>8. SSL- bzw. TLS-Verschlüsselung</h2>
                <p>Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von "http://" auf "https://" wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</p>
                <p>Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.</p>
                <p>&nbsp;</p>
                <h2>9. Auskunft, Sperrung, Löschung</h2>
                <p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.</p>
                <p>&nbsp;</p>
                <h2>10. Widerspruch gegen Werbe-Mails</h2>
                <p>Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.</p>
                <p>&nbsp;</p>
                <p>Quelle: eRecht24</p>
                <p>&nbsp;</p>
            </div>

        </div>

    </div>

</div>

<!-- Footer Start -->
<footer>
    <app-contact-block></app-contact-block>
    <app-footer></app-footer>
</footer>
<!-- Footer End -->