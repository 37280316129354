import { Component, OnInit } from '@angular/core';
import{EnService} from './../services/data/en.service';
import{GeService} from './../services/data/ge.service';
@Component({
  selector: 'app-online-marketing',
  templateUrl: './online-marketing.component.html',
  styleUrls: ['./online-marketing.component.css']
})
export class OnlineMarketingComponent implements OnInit {
  settings :any={imagealign:"right",Iscol:"no",isfirst:true,d_mainrow:{d_class:'row row-eq-height',d_col:[{d_class:'col-12 col-md-6 align-self-center'},{d_class:'col-12 col-md-6 align-self-center d-none d-sm-block d-lg-block'}]}};
  settings2 :any={imagealign:"right",Iscol:"no",isfirst:false,d_mainrow:{d_class:'row row-eq-height',d_col:[{d_class:'col-12 col-md-8 align-self-center'},{d_class:'col-12 col-md-4 align-self-center d-none d-sm-block d-lg-block'}]}};
  settings3 :any={imagealign:"left",Iscol:"yes",isfirst:false,d_mainrow:{d_class:'row row-eq-height',d_col:[{d_class:'col-12 col-md-4'},{d_class:'col-12 col-md-8'},{d_class:'col-12 col-md-6'},{d_class:'col-12 col-md-6'}]}};
  settings4 :any={imagealign:"right",Iscol:"no",isfirst:false,d_mainrow:{d_class:'row row-eq-height',d_col:[{d_class:'col-12 col-md-8'},{d_class:'col-12 col-md-4 d-none d-sm-block d-lg-block'}]}};
  settings5 :any={imagealign:"",Iscol:"no",isfirst:false,d_mainrow:{d_class:'row row-eq-height',d_col:[{d_class:'col-12 col-md-12'}]}};

  Page_content :any;
  //constructor(private en :EnService)
  constructor(private ge :GeService)
  {

  }

  ngOnInit(): void {
   // this.Page_content =this.en.get().OnlineMarketing;
    this.Page_content =this.ge.get().OnlineMarketing;
    window.scrollTo(0, 0);
  }

}
