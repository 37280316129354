import { Component, OnInit } from '@angular/core';
import{EnService} from './../services/data/en.service';
import{GeService} from './../services/data/ge.service';
@Component({
  selector: 'app-mobile-app',
  templateUrl: './mobile-app.component.html',
  styleUrls: ['./mobile-app.component.css']
})
export class MobileAppComponent implements OnInit {
  settings :any={imagealign:"right",isfirst:true};
  Page_content :any;
  //constructor(private en :EnService)
  constructor(private ge :GeService)
  {
  }

  ngOnInit(): void {
   // this.Page_content =this.en.get().MobileApp;
  this.Page_content =this.ge.get().MobileApp;
  window.scrollTo(0, 0);
  }

}
