<ng-template [ngIf]="ms.imagealign =='left'">

         <!-- Common Section Image Left Start web-designe-section-->
    <div class="common-section" [ngClass]="{'firstblock':ms.isfirst}">
      <div class="container-xl">


          <div [ngClass]="ms.d_mainrow.d_class">
              <div [ngClass]="ms.d_mainrow.d_col[0].d_class">
                  <img class="img-fluid" [src]="blockcontent.imageSrc[0]" alt="blockcontent.imageSrc[1]" />
              </div>

              <div [ngClass]="ms.d_mainrow.d_col[1].d_class">
                  <h3 class="heading-text text-blue text-uppercase pb-4" [innerHTML]="blockcontent.h2"></h3>

                  <div *ngIf="ms.Iscol =='no'">
                    <p [innerHTML]="blockcontent.p"></p>
                  </div>
                  <div *ngIf="ms.Iscol =='yes'" class="row">
                    <div [ngClass]="ms.d_mainrow.d_col[2].d_class"> 
                    <p [innerHTML]="blockcontent.c1"></p>
                </div>
                <div [ngClass]="ms.d_mainrow.d_col[3].d_class">
                    <p [innerHTML]="blockcontent.c2"></p>
                </div>
                </div>
              </div>
          </div>

      </div>
  </div><!-- Common Section Image Left End -->

    </ng-template>
    <ng-template [ngIf]="ms.imagealign =='right'">
         <!-- Common Section Image Right Start   -->
    <div class="common-section" [ngClass]="{'firstblock':ms.isfirst}">
      <div class="container-xl">

        <div [ngClass]="ms.d_mainrow.d_class">
          <div [ngClass]="ms.d_mainrow.d_col[0].d_class">
                <h3 class="heading-text text-blue text-uppercase pb-4" [innerHTML]="blockcontent.h2"></h3>
				<div class="d-block d-sm-none d-lg-none">
				  <img class="img-fluid" [src]="blockcontent.imageSrc[0]" alt="blockcontent.imageSrc[1]" />
				 </div>
                <div *ngIf="ms.Iscol =='no'">
                  <p [innerHTML]="blockcontent.p"></p>
                  <div *ngIf="ms.isfirst == true">
                  <div class="col-md-9">
                    <a  class="btn btn-blue btn-block btn-send px-3 py-2 mt-4 text-left" href={{blockcontent.buttonLink}} ><small>{{blockcontent.buttonText}}</small> <span class="small-icon float-right"><i class={{blockcontent.buttonIcon}}></i></span></a>
         
                  </div></div>
                </div>
                <div *ngIf="ms.Iscol =='yes'" class="row">
                    <div [ngClass]="ms.d_mainrow.d_col[2].d_class"> 
                    <p [innerHTML]="blockcontent.c1"></p>
                </div>
                <div [ngClass]="ms.d_mainrow.d_col[3].d_class">
                    <p [innerHTML]="blockcontent.c2"></p>
                </div>
                </div>
             </div>
             <div [ngClass]="ms.d_mainrow.d_col[1].d_class">
                  <img class="img-fluid" [src]="blockcontent.imageSrc[0]" alt="blockcontent.imageSrc[1]" />
              </div>
          </div>

      </div>
  </div><!-- Common Section Image Right End -->
    </ng-template>
    <ng-template [ngIf]="ms.imagealign ==''">
        <!-- Common Section Image Right Start  class="common-section web-designe-section"-->
   <div class="" [ngClass]="{'firstblock':ms.isfirst}">
     <div class="container-xl">

       <div [ngClass]="ms.d_mainrow.d_class">
         <div [ngClass]="ms.d_mainrow.d_col[0].d_class">
               <h3 class="heading-text text-blue text-uppercase pb-4" [innerHTML]="blockcontent.h2"></h3>
               
                 <p [innerHTML]="blockcontent.p"></p>
               
            </div>
         </div>

     </div>
 </div><!-- Common Section Image Right End -->
   </ng-template>

   <ng-template [ngIf]="ms.imagealign =='overlap'">

    <!-- Common Section Image Left Start web-designe-section-->
<div class="common-section" [ngClass]="{'firstblock':ms.isfirst}">
 <div class="container-xl">

    <div class="row">
      <div class="col-12">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2700.942790410072!2d8.486501475361536!3d47.39354910266691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47900bb76c2e3ad5%3A0x382d94aab113975!2sMax-H%C3%B6gger-Strasse%206%2C%208048%20Z%C3%BCrich%2C%20Switzerland!5e0!3m2!1sen!2sin!4v1717590962570!5m2!1sen!2sin" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>

<!-- <div [ngClass]="ms.d_mainrow.d_class">
         <div [ngClass]="ms.d_mainrow.d_col[0].d_class">
             <img class="img-fluid" [src]="blockcontent.imageSrc[0]" alt="blockcontent.imageSrc[1]" />
             <div>
              <google-map
                height="500px"
                width="100%"
                [zoom]="zoom"
                [center]="center"
                [options]="options">
                
                <map-marker
                  *ngFor="let marker of markers"
                   [position]="marker.position"
                   [label]="marker.label"
                   [title]="marker.title"
                   [options]="marker.options">
                 </map-marker>
              </google-map> -->
             
              <!--<google-map height="500px"
              width="100%" [zoom]="zoom"
              [center]="center" [options]="options" >
              <map-marker *ngFor="let marker of markers"
                [position]="marker.position"
                [label]="marker.label" [title]="marker.title"
                [options]="marker.options">
              </map-marker>
            </google-map> 

            

            </div>
         </div>

         <div [ngClass]="ms.d_mainrow.d_col[1].d_class">
             <h5 class="heading-cotact text-blue text-uppercase pb-4" [innerHTML]="blockcontent.h2"></h5>

             <div *ngIf="ms.Iscol =='no'">
               <!-- <p [innerHTML]="blockcontent.p"></p> -->
               
               <!--<form name="contactForm" #contactForm="ngForm"
               (ngSubmit)="submit()">
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="contactPost.fname"
                  name="fname" #fname="ngModel" placeholder="Vorname" required>
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="contactPost.lname"
                  name="lname" #lname="ngModel"  placeholder="Nachname" required>
                </div>
                <div class="form-group">
                  <input type="email" class="form-control"
                  [(ngModel)]="contactPost.email"
                  name="email" #email="ngModel"
                  placeholder="E-Mail" required>
                </div>
                <button type="submit" class="btn btn-blue btn-block btn-send"><small><span [innerHTML]="blockcontent.buttonText2"></span></small> <span class="small-icon float-right"><i class="fa fa-arrow-right"></i></span></button>
                <button type="submit" class="btn btn-blue btn-block btn-send"><small><span [innerHTML]="blockcontent.buttonText1"></span></small> <span class="small-icon float-right"><i class="fa fa-arrow-right"></i></span></button>

              
              </form>
             </div>
             
         </div>
     </div>-->

 </div>
</div><!-- Common Section Image Left End -->

</ng-template>