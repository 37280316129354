
	
		<!-- Our Services Start -->
	<div class="service-section">
		<div class="container-xl">
			<div class="row">
				<div class="col-12 col-md-12">
					<div *ngIf="blockcontent.h3 !== undefined" class="pb-4 mb-4 aaa">
						<p class="heading-text text-blue text-center">{{blockcontent.h3}}</p>
						<!-- <p class="subtitle text-black font-weight-bolder text-center mb-1">{{blockcontent.p}}</p> -->
					</div>
					<div *ngIf="blockcontent.h3 == undefined">
						<p class="subtitle text-black font-weight-bolder text-center mb-1">{{blockcontent.p}}</p>
					</div>
					
				</div>
			</div>
			<div class="row">
				<div class="col mb-4" [ngClass]="colclass" *ngFor="let item of blockcontent.sections">
				<app-infoblock [content]="item" [pagename]="name"></app-infoblock>
					
				</div>
				
					
			</div>
		</div>
	</div><!-- Our Services End -->
