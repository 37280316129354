import { Component } from '@angular/core';
import { Meta, Title} from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  id!: any;
  private sub: any;
  constructor(titleService: Title, metaServices: Meta, public router: Router,private activatedRoute: ActivatedRoute,) {
    // router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     var data = route.snapshot.data;
    //     var title = this.getTitle(router.routerState, router.routerState.root); //.join('-')
        
    //     titleService.setTitle(title[0]);
    //     metaServices.addTag({ name: 'title', content: title[1]});
    //     metaServices.addTag({ name: 'Description', content: title[2]});
    //   }
    // });
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(e => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
    ).subscribe(data => {
      
       let title=data;
       titleService.setTitle(title.title);
       metaServices.addTag({ name: 'title', content: title.metatitle});
       metaServices.addTag({ name: 'description', content: title.description});
    });
  }




  getTitle(state, parent) {
    var data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
      data.push(parent.snapshot.data.metatitle);
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

}
