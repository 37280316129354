import { Component, OnInit } from '@angular/core';
import{EnService} from './../services/data/en.service';
import{GeService} from './../services/data/ge.service';
@Component({
  selector: 'app-search-engine-optimization',
  templateUrl: './search-engine-optimization.component.html',
  styleUrls: ['./search-engine-optimization.component.css']
})
export class SearchEngineOptimizationComponent implements OnInit {
  settings :any={imagealign:"right",Iscol:"no",isfirst:true,d_mainrow:{d_class:'row row-eq-height',d_col:[{d_class:'col-12 col-md-6 pt-md-5 mt-md-5'},{d_class:'col-12 col-md-6 d-none d-sm-block d-lg-block'}]}};
  settings2 :any={imagealign:"right",Iscol:"no",isfirst:false,d_mainrow:{d_class:'row row-eq-height',d_col:[{d_class:'col-12 col-md-6'},{d_class:'col-12 col-md-6 d-none d-sm-block d-lg-block'}]}};
  settings3 :any={imagealign:"left",Iscol:"no",isfirst:false,d_mainrow:{d_class:'row row-eq-height',d_col:[{d_class:'col-12 col-md-4'},{d_class:'col-12 col-md-8 align-self-center'}]}};
  settings4 :any={imagealign:"right",Iscol:"no",isfirst:false,d_mainrow:{d_class:'row row-eq-height',d_col:[{d_class:'col-12 col-md-7'},{d_class:'col-12 col-md-5 d-none d-sm-block d-lg-block'}]}};
  settings5 :any={imagealign:"left",Iscol:"no",isfirst:false,d_mainrow:{d_class:'row row-eq-height',d_col:[{d_class:'col-12 col-md-4'},{d_class:'col-12 col-md-8'}]}};
  settings6 :any={imagealign:"right",Iscol:"no",isfirst:false,d_mainrow:{d_class:'row row-eq-height',d_col:[{d_class:'col-12 col-md-8'},{d_class:'col-12 col-md-4 d-none d-sm-block d-lg-block'}]}};
  settings7 :any={imagealign:"",Iscol:"no",isfirst:false,d_mainrow:{d_class:'row row-eq-height text-center',d_col:[{d_class:'col-12 col-md-12 max-auto text-center'}]}};
  Page_content :any;
  //constructor(private en :EnService)
  constructor(private ge :GeService)
  {
  }

  ngOnInit(): void {
    //this.Page_content =this.en.get().SearchEngine;
    this.Page_content =this.ge.get().SearchEngine;
    window.scrollTo(0, 0);
  }

}
