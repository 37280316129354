<header>
    <app-header-top></app-header-top>
    <app-head></app-head>
    </header>
    <app-flex-width-container [modulesettings]="settings" [content]="Page_content.BlockSection"></app-flex-width-container>
    <app-infoblock-holder [col]="4" [content]="Page_content.Infoblock"></app-infoblock-holder>

    <div class="service-section">
      <div class="container-xl">
        <div class="row">
          
          <div class="col-12 col-sm-6 col-lg-6 my-5">
            <div class="seller-box">
              <div class="row">
                <div class="col-12 col-sm-7 col-lg-7">
                  <h3 class="text-uppercase text-space text-white font-weight-bold">LogoExpress</h3>
                  <p class="text-space1 text-warning font-weight-bold mb-0">Einfaches Logo ab 400.-CHF <br />mit 3 Änderungen</p>
                </div>
                <div class="col-12 col-sm-5 col-lg-5 mt-3">
                  <a class="btn btn-blue btn-block btn-send px-4 text-left" href="/Kontakt" ><small>Kontakt</small> <span class="small-icon float-right"><i class="fa fa-arrow-right"></i></span></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-6 my-5">
            <div class="seller-box">
              <div class="row">
                <div class="col-12 col-sm-7 col-lg-7">
                  <h3 class="text-uppercase text-space text-white font-weight-bold">LogoPro</h3>
                  <p class="text-space1 text-warning font-weight-bold mb-0">Professionelles Logo ab 1490.-CHF</p>
                </div>
                <div class="col-12 col-sm-5 col-lg-5 mt-3">
                  <a class="btn btn-blue btn-block btn-send px-4 text-left" href="/Kontakt" ><small>Kontakt</small> <span class="small-icon float-right"><i class="fa fa-arrow-right"></i></span></a>
                </div>
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </div>

    <app-portfolio [content]="Page_content.Portfolio"></app-portfolio>
    <app-full-width-holder [modulesettings]="settings2" [content]="Page_content.BlockSection1"></app-full-width-holder>
    <app-full-width-holder [modulesettings]="settings3" [content]="Page_content.BlockSection2"></app-full-width-holder>
    <app-full-width-holder [modulesettings]="settings4" [content]="Page_content.BlockSection2a"></app-full-width-holder>
    
      <!-- Footer Start -->
    <footer>
    <app-contact-block></app-contact-block>
    <app-footer></app-footer>
    </footer><!-- Footer End -->
