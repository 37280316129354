import { Component, OnInit } from '@angular/core';
import{EnService} from './../services/data/en.service';
import{GeService} from './../services/data/ge.service';
@Component({
  selector: 'app-facebook-marketing',
  templateUrl: './facebook-marketing.component.html',
  styleUrls: ['./facebook-marketing.component.css']
})
export class FacebookMarketingComponent implements OnInit {
  settings :any={imagealign:"right",Iscol:"no",isfirst:true,d_mainrow:{d_class:'row row-eq-height',d_col:[{d_class:'col-12 col-md-6 pt-5 mt-5'},{d_class:'col-12 col-md-6 d-none d-sm-block d-lg-block'}]}};
  settings2 :any={imagealign:"left",Iscol:"yes",isfirst:false,d_mainrow:{d_class:'row row-eq-height',d_col:[{d_class:'col-12 col-md-4'},{d_class:'col-12 col-md-8'},{d_class:'col-12 col-md-6'},{d_class:'col-12 col-md-6'}]}};
  settings3 :any={imagealign:"right",Iscol:"yes",isfirst:false,d_mainrow:{d_class:'row row-eq-height',d_col:[{d_class:'col-12 col-md-8'},{d_class:'col-12 col-md-4 d-none d-sm-block d-lg-block'},{d_class:'col-12 col-md-6'},{d_class:'col-12 col-md-6'}]}};
  Page_content :any;
  //constructor(private en :EnService)
  constructor(private ge :GeService)
  { }

  ngOnInit(): void {
    //this.Page_content =this.en.get().Facebookmarketing;
    this.Page_content =this.ge.get().Facebookmarketing;
    window.scrollTo(0, 0);
  }

}
