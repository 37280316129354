import { Component, OnInit } from '@angular/core';
import{EnService} from  '../../services/data/en.service';
import{GeService} from '../../services/data/ge.service';

declare let $: any;
declare let jQuery: any;
@Component({
  selector: 'app-head',
  templateUrl: './head.component.html',
  styleUrls: ['./head.component.css']
})
export class HeadComponent implements OnInit {
  Page_content :any;
  is_open = "";
  is_show = "";
  constructor(private ge :GeService) { }

  ngOnInit(): void {
    this.Page_content =this.ge.get().headerblock;
  }
  toggleClick(id){
    if(this.is_open){
      this.is_open = "";
    }
    else{
      this.is_open = "is_open";
    }
    document.getElementById(id).classList.toggle('show');
  }

  menuClick(id) {  
    if(this.is_show){
      this.is_show = "";
    }
    else{
      this.is_show = "show";
    }  
    document.getElementById(id).classList.toggle('show');
  };


  ngAfterViewInit() {
    var header = $(".start-style");
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 10) {
        header.removeClass('start-style').addClass("scroll-on");
      } else {
        header.removeClass("scroll-on").addClass('start-style');
      }
    });


    //Menu On Hover
    $('body').on('mouseenter mouseleave', '.nav-item', function (e) {
      if ($(window).width() > 750) {
        var _d = $(e.target).closest('.nav-item'); _d.addClass('show');
        setTimeout(function () {
          _d[_d.is(':hover') ? 'addClass' : 'removeClass']('show');
        }, 1);
      }
    });

    //For Dashboard Menu
    $('.dashboard-nav-responsive').on('click', function (e) {
      e.preventDefault();
      $(this).toggleClass('active');
      var dashboardNavContainer = $('body').find(".utf_dashboard_navigation");
      if ($(this).hasClass('active')) {
        $(dashboardNavContainer).addClass('active');
      } else {
        $(dashboardNavContainer).removeClass('active');
      }
    });

    $('.utf_dashboard_navigation ul li a').on('click', function () {
      if ($(this).closest('li').has('ul').length) {
        $(this).parent('li').toggleClass('active');
      }
    });
    $(window).on('load resize', function () {
      var main_wrapperHeight = window.innerHeight;
      var headerHeight = $("#header_part").height();
      var winWidth = $(window).width();
      if (winWidth > 992) {
        $(".utf_dashboard_navigation_inner_block").css('max-height', main_wrapperHeight - headerHeight);
      } else {
        $(".utf_dashboard_navigation_inner_block").css('max-height', '');
      }
    });
  }




}
