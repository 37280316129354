import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-flex-width-container',
  templateUrl: './flex-width-container.component.html',
  styleUrls: ['./flex-width-container.component.css']
})
export class FlexWidthContainerComponent implements OnInit {
  @Input("modulesettings") ms:any;
  @Input("content") blockcontent:any;
  constructor() { }

  ngOnInit(): void {
    console.log("This is the parent data", this.ms.imagealign);
  }

}
