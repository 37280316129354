import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FuncService } from './../../services/func.service'
@Component({
  selector: 'app-infoblock-holder',
  templateUrl: './infoblock-holder.component.html',
  styleUrls: ['./infoblock-holder.component.css']
})
export class InfoblockHolderComponent implements OnInit {
  @Input("content") blockcontent:any;
   @Input("col") blocksize:string="0";
   @Input("page") name:string;

  public colclass :string[] ;
  constructor(private funcs: FuncService) {   }

  ngOnInit(): void {
    this.colclass = this.funcs.getColstruct(this.blocksize);
  }



}
