import { Component, OnInit } from '@angular/core';
import{EnService} from './../services/data/en.service';
import{GeService} from './../services/data/ge.service';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  settings :any={imagealign:"overlap",isfirst:true,Iscol:"no",d_mainrow:{d_class:'row',d_col:[{d_class:'col-12 col-md-8'},{d_class:'col-12 col-md-4 portlet pt-3'}]}};
  Page_content :any;
  constructor(private ge :GeService) { }

  ngOnInit(): void {
    this.Page_content =this.ge.get().ContactUS;
    window.scrollTo(0, 0);
  }

}
