import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-infoblock',
  templateUrl: './infoblock.component.html',
  styleUrls: ['./infoblock.component.css']
})
export class InfoblockComponent implements OnInit {
  @Input("content") blockcontent:any;
  @Input("pagename") currentpage:string;
  constructor() { }

  ngOnInit(): void {
  }

}
