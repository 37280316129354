<ng-template [ngIf]="ms.imagealign =='left'">

  <!-- Common Section Image Left Start web-designe-section-->
<div class="common-section" [ngClass]="{'firstblock':ms.isfirst}">
<!-- <div class="container-xl"> -->
  <!-- class="d-flex" -->
   <div [ngClass]="ms.d_mainrow.d_class" >
       <!-- <div class="col-12 col-md-6 justify-content-start"> -->
        <!-- class="col-12 col-md-6 text-right -->
        <div [ngClass]="ms.d_mainrow.d_col[0].d_class">
           <img class="img-fluid" [src]="blockcontent.imageSrc[0]" alt="blockcontent.imageSrc[1]" />
       </div>
       <!-- class="col-12 col-md-6 align-self-center" style="padding-right: 16vw;" -->
       <div [ngClass]="ms.d_mainrow.d_col[1].d_class">
           <h3 class="heading-text text-blue text-uppercase pb-4" [innerHTML]="blockcontent.h2"></h3>

           <p [innerHTML]="blockcontent.p"></p>
       </div>
   </div>


</div><!-- Common Section Image Left End -->

</ng-template>
<ng-template [ngIf]="ms.imagealign =='right'">
  <!-- Common Section Image Right Start web-designe-section-->
<div class="common-section" [ngClass]="{'firstblock':ms.isfirst}">


   <div class="d-flex">
       <div class="col-12 col-md-6" style="padding-left: 8vw;">
         <h3 class="heading-text text-blue text-uppercase pb-4" [innerHTML]="blockcontent.h2"></h3>
         <div class="d-block d-sm-none d-lg-none">
          <img class="img-fluid" [src]="blockcontent.imageSrc[0]" alt="blockcontent.imageSrc[1]" />
         </div>

         <p [innerHTML]="blockcontent.p"></p>

         <!-- <div *ngIf="ms.Iscol =='yes'" class="row">
          <div [ngClass]="ms.d_mainrow.d_col[0].d_class"> 
          <img class="img-fluid" [src]="blockcontent.logoSrc[0]" alt="blockcontent.logoSrc[1]" />
          <h1 [innerHTML]="blockcontent.h_1"></h1>
          <p [innerHTML]="blockcontent.c1"  class="text-dark"></p>
      </div>
      <div [ngClass]="ms.d_mainrow.d_col[1].d_class">
          <img class="img-fluid" [src]="blockcontent.logoSrc1[0]" alt="blockcontent.logoSrc1[1]" />
          <h1 [innerHTML]="blockcontent.h_2"></h1>
          <p [innerHTML]="blockcontent.c2" class="text-dark"></p>
      </div>
      </div> -->

         <div *ngIf="ms.isfirst == true">
          <div class="col-md-7">
            <a class="btn btn-blue btn-block btn-send px-2 text-left" href={{blockcontent.buttonLink}} ><small>{{blockcontent.buttonText}}</small> <span class="small-icon float-right"><i class={{blockcontent.buttonIcon}}></i></span></a>
 
          </div></div>
      </div>
       <div class="col-12 col-md-6 justify-content-end d-none d-sm-block d-lg-block">
           <img class="img-fluid" [src]="blockcontent.imageSrc[0]" alt="blockcontent.imageSrc[1]" />
       </div>
   </div>


</div><!-- Common Section Image Right End -->
</ng-template>
<ng-template [ngIf]="ms.imagealign ==''">
  <!-- Common Section Image Right Start web-designe-section-->
<div class="common-section" [ngClass]="{'firstblock':ms.isfirst}">
<div class="container-xl">

 <div [ngClass]="ms.d_mainrow.d_class">
   <div [ngClass]="ms.d_mainrow.d_col[0].d_class">
         <h3 class="heading-text text-blue text-uppercase pb-4" [innerHTML]="blockcontent.h2"></h3>
         
           <p [innerHTML]="blockcontent.p"></p>
         
      </div>
   </div>

</div>
</div><!-- Common Section Image Right End -->
</ng-template>