import { Component, OnInit } from '@angular/core';
import{GeService} from './../services/data/ge.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  Page_content :any;
  constructor(private ge :GeService) { }

  ngOnInit(): void {
    //this.Page_content =this.ge.get().P;
    window.scrollTo(0, 0);
  }

}
