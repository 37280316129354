
  <header>
  <app-header-top></app-header-top>
  <app-head></app-head>
  </header>
  <app-full-width-holder [modulesettings]="settings"  [content]="Page_content.BlockSection" ></app-full-width-holder>
  <app-full-width-holder [modulesettings]="settings2"  [content]="Page_content.BlockSection1" ></app-full-width-holder>

  <app-infoblock-holder [col]="Page_content.Infoblock.col" [content]="Page_content.Infoblock" [page]="name"></app-infoblock-holder>
    <!-- Footer Start -->
  <footer>
  <app-contact-block></app-contact-block>
  <app-footer></app-footer>
  </footer><!-- Footer End -->
