import { stringify } from '@angular/compiler/src/util';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class FuncService {
  colclass: string[]= [];

  constructor() { }
  getColstruct(a){
    this.colclass =[];
    console.log(a);
    switch(a.toString()) {
       case "1": {
          this.colclass.push('col-md-12');
          break;
       }
       case "2": {
        this.colclass.push('col-md-6');
          break;
       }
        case "3": {
          this.colclass.push('col-md-4');
          break;
       }
        case "4": {
          this.colclass.push('col-md-3');
          break;
       }
       case "5": {
        this.colclass.push('col');
        break;
     }
        case "6": {
          this.colclass.push('col-md-2');
          break;
       }
       case "12": {
        this.colclass.push('col-md-1');
          break;
       }
       default: {
        this.colclass.push('col-md-12');
          break;
       }
    }
    return this.colclass;
  }

}
