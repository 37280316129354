export class contactPostModel{
    fname:string;
    lname:string;
    email:string;
    constructor(){
        this.fname='';
        this.lname='';
        this.email='';
    }
}

export class GeneralForm{
    companies:string;
    name:string;
    email:string;
    phone:string;
    street:string;
    postcode:string;
    news:string;
    constructor(){
        this.companies='';
        this.name='';
        this.email='';
        this.phone='';
        this.street='';
        this.postcode='';
        this.news='';
       
    }
}
export class Appointment{
    companies:string;
    name:string;
    email:string;
    phone:string;
    street:string;
    postcode:string;
    news:string;
    apartmentType:string;
    date:string;
    time:string;
    constructor(){
        this.companies='';
        this.name='';
        this.email='';
        this.phone='';
        this.street='';
        this.postcode='';
        this.news='';
        this.apartmentType='';
        this.date='';
        this.time='';
    }
}