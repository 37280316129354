<header>
    <app-header-top></app-header-top>
    <app-head></app-head>
</header>


    <app-flex-width-container [modulesettings]="settings" [content]="Page_content.BlockSection"></app-flex-width-container>
    <!--<app-infoblock-holder [col]="4" [content]="Page_content.Infoblock"></app-infoblock-holder> -->

    <div class="service-section">
        <div class="container-xl">
          <div class="row">
  
            <div class="col-12 col-sm-12 col-lg-12">
              <h3 class="heading-text text-blue text-uppercase pb-3">All-in-One Tool: Umfassende Pakete für Ihren perfekten Online-Auftritt</h3>
            </div>
            
            <div class="col-12 col-sm-6 col-lg-4 my-4">
              <div class="seller-box blue-bg">
                <p class="subtitle text-uppercase text-left text-blue font-weight-bold mt-4">Das Paket S</p>
                <p class="service-subtitle text-left text-blue">Das perfekte Starter-Paket</p>
                <!--<p class="subtitle text-left mt-2">Ab</p>
                <p class="subtitle text-uppercase text-left text-blue font-weight-bolder">CHF 49.-</p>
                <p class="service-subtitle text-left text-blue">monatlich</p> -->
                <p>Firmenadresse, Beschrieb und Logo</p>
                <ul class="m-0 pl-3 pb-3">
                  <li>Bis zu 10 Verzeichnisse</li>
                </ul>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <a _ngcontent-yge-c140="" href="/Kontakt" class="ctn-btn btn btn-blue btn-block btn-send px-4 text-left"><small _ngcontent-yge-c140="">Vereinbaren Sie einen Beratungstermin</small><span _ngcontent-yge-c140="" class="small-icon float-right"><i _ngcontent-yge-c140="" class="fa fa-arrow-right"></i></span></a>
                  
              </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-4 my-4">
              <div class="seller-box bestseller-box red-bg">
                <!--<p class="text-left text-read mb-1">BESTSELLER</p> -->
                <p class="subtitle text-uppercase text-left text-blue font-weight-bold mt-4">Das Paket M</p>
                <p class="service-subtitle text-left text-blue">Das Basis-Paket</p>
                <!-- <p class="subtitle text-left mt-2">Ab</p>
                <p class="subtitle text-uppercase text-left text-blue font-weight-bolder">CHF 207.50.-</p>
                <p class="service-subtitle text-left text-blue">monatlich</p> -->
                <p>Firmenadresse, Beschrieb und Logo</p>
                <ul class="m-0 pl-3 pb-3">
                  <li>Bis zu 20 Verzeichnisse</li>
                </ul>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <a _ngcontent-yge-c140="" href="/Kontakt" class="ctn-btn btn btn-blue btn-block btn-send px-4 text-left"><small _ngcontent-yge-c140="">Vereinbaren Sie einen Beratungstermin</small><span _ngcontent-yge-c140="" class="small-icon float-right"><i _ngcontent-yge-c140="" class="fa fa-arrow-right"></i></span></a>
                  
              </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-4 my-4">
              <div class="seller-box blue-bg">
                <p class="subtitle text-uppercase text-left text-blue font-weight-bold mt-4">Das Paket L</p>
                <p class="service-subtitle text-left text-blue">Das umfangreiche Experten-PaketAb</p>
                <!-- <p class="subtitle text-left mt-2">Ab</p>
                <p class="subtitle text-uppercase text-left text-blue font-weight-bolder">CHF 365.-</p>
                <p class="service-subtitle text-left text-blue">monatlich</p> -->
                <p>Firmenadresse, Beschrieb und Logo</p>
                <ul class="m-0 pl-3 pb-3">
                  <li>Über 40 Verzeichnisse</li>
                </ul>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <a _ngcontent-yge-c140="" href="/Kontakt" class="ctn-btn btn btn-blue btn-block btn-send px-4 text-left"><small _ngcontent-yge-c140="">Vereinbaren Sie einen Beratungstermin</small><span _ngcontent-yge-c140="" class="small-icon float-right"><i _ngcontent-yge-c140="" class="fa fa-arrow-right"></i></span></a>
                  
              </div>
            </div>          
          </div>
        </div>
      </div>

      <app-full-width-holder [modulesettings]="settings3" [content]="Page_content.BlockSection1"></app-full-width-holder>
  


<!-- Footer Start -->
<footer>
    <app-contact-block></app-contact-block>
    <app-footer></app-footer>
</footer><!-- Footer End -->

