<div class="footer-top">
  <div class="bot-contact-form">
    <div class="container-xl">
      <div class="bot-contact-form-inner">
        <div class="row">
          <div class="col-12 col-md-5"></div>
          <div class="col-12 col-md-7">
            <div class="bot-contact-form-wrap">
              <h3 class="heading-text text-white text-uppercase text-left">{{this.Page_content.BlockSection.h2}}</h3>
              <p class="text-white subtitle mb-3">{{this.Page_content.BlockSection.p}}</p>
              <div class="row">
                <div class="col-12 col-md-6 mb-3">
                  <a class="btn btn-block text-left" [ngClass]="{'btn-blue':isCollapsedfst,'btn-blue-active':!isCollapsedfst}" (click)="collapsefst.toggle();collapsend.toggle()" [attr.aria-expanded]="!isCollapsedfst" aria-controls="generalRequest">
                    <small>{{this.Page_content.BlockSection.buttonText}}</small> <span class="small-icon float-right"><i class="fa fa-arrow-right"></i></span>
                  </a>
                </div>
                <div class="col-12 col-md-6 mb-3">
                  <a class="btn btn-block text-left" [ngClass]="{'btn-blue':isCollapsednd,'btn-blue-active':!isCollapsednd}" (click)="collapsend.toggle();collapsefst.toggle()" [attr.aria-expanded]="!isCollapsednd" aria-controls="consultationAppointment">
                    <small>{{this.Page_content.BlockSection.buttonText1}}</small> <span class="small-icon float-right"><i class="fa fa-arrow-right"></i></span>
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-12">
                  <div  #collapsefst="ngbCollapse" [(ngbCollapse)]="isCollapsedfst" id="generalRequest">

                    <form name="generalForm" #generalForm="ngForm"
                    (ngSubmit)="generalFormsubmit()">

                      <div class="messages"></div>
                      <div class="controls">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="text" name="company" class="form-control" placeholder="{{this.Page_content.BlockSection.placeholder}}"
                              [(ngModel)]="genFormPost.companies" name="companies" #companies="ngModel">
                              <div class="help-block with-errors"></div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="text" name="name" class="form-control" placeholder="{{this.Page_content.BlockSection.placeholder1}}*" required="required" data-error="Name is required."
                              [(ngModel)]="genFormPost.name" name="name" #name="ngModel">
                              <small *ngIf="(name.dirty || name.touched) &&  name.invalid"
                              class="help-block with-errors text-white">
                              <div *ngIf="name.errors.required">
                                  Name field is required.
                              </div>
                          </small>
                             
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="email" name="email" class="form-control" placeholder="{{this.Page_content.BlockSection.placeholder2}} *" required="required" data-error="Valid email is required."
                              [(ngModel)]="genFormPost.email" name="email" #email="ngModel">
                              <small *ngIf="(email.dirty || email.touched) &&  email.invalid"
                              class="help-block with-errors text-white">
                              <div *ngIf="email.errors.required">
                                  Email field is required.
                              </div>
                          </small>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="tel" name="phone" class="form-control" placeholder="{{this.Page_content.BlockSection.placeholder3}}"
                              [(ngModel)]="genFormPost.phone" name="phone" #phone="ngModel">
                              <div class="help-block with-errors"></div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="text" name="streetno" class="form-control" placeholder="{{this.Page_content.BlockSection.placeholder4}}"
                              [(ngModel)]="genFormPost.street" name="street" #street="ngModel">
                              <div class="help-block with-errors"></div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="text" name="postcode" class="form-control" placeholder="{{this.Page_content.BlockSection.placeholder5}}"
                              [(ngModel)]="genFormPost.postcode" name="postcode" #postcode="ngModel">
                              <div class="help-block with-errors"></div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <textarea name="message" class="form-control" placeholder="{{this.Page_content.BlockSection.placeholder6}} *" rows="3" required data-error="Please,leave us a message."
                              [(ngModel)]="genFormPost.news" name="news" #news="ngModel"></textarea>
                              <small *ngIf="(news.dirty || news.touched) &&  news.invalid"
                              class="help-block with-errors text-white">
                              <div *ngIf="news.errors.required">
                                  News field is required.
                              </div>
                          </small>
                             
                            </div>
                          </div>
                          <div class="col-md-6"></div>
                          <div class="col-md-6 mb-3">
                            <button type="submit" class="btn btn-blue btn-block btn-send"><small>{{this.Page_content.BlockSection.buttonText2}}</small> <span class="small-icon float-right"><i class="fa fa-arrow-right"></i></span></button>
                          </div>
                        </div>

                      </div>
                    </form>

                  </div>
                </div>
                <div class="col-12 col-md-12">
                  <div  #collapsend="ngbCollapse" [(ngbCollapse)]="isCollapsednd" id="consultationAppointment">

                    <form name="appointmentForm" #appointmentForm="ngForm"
                    (ngSubmit)="appFormsubmit()">

                      <div class="messages"></div>
                      <div class="controls">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input class="datepicker form-control" placeholder="{{this.Page_content.BlockSection.placeholder7}}"  data-date-format="dd/mm/yyyy" name="date"
                              [(ngModel)]="appointmentPost.date" name="date" #date="ngModel">
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <select class="form-control" id="time" required="required" data-error="Time is required."
                              [(ngModel)]="appointmentPost.time" name="time" #time="ngModel">
                                <option value='' disabled>Zeit *</option>
                                <option value="08.00 - 09.00">08.00 - 09.00</option>
                                <option value="09.00 - 10.00">09.00 - 10.00</option>
                                <option value="10.00 - 11.00">10.00 - 11.00</option>
                                <option value="11.00 - 12.00">11.00 - 12.00</option>
                                <option value="12.00 - 13.00">12.00 - 13.00</option>
                                <option value="13.00 - 14.00">13.00 - 14.00</option>
                                <option value="14.00 - 15.00">14.00 - 15.00</option>
                                <option value="15.00 - 16.00">15.00 - 16.00</option>
                                <option value="16.00 - 17.00">16.00 - 17.00</option>
                                <option value="17.00 - 18.00">17.00 - 18.00</option>
                              </select>
                              <small *ngIf="(time.dirty || time.touched) &&  time.invalid"
                              class="help-block with-errors text-white">
                              <div *ngIf="time.errors.required">
                                  Time field is required.
                              </div>
                          </small>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input class="form-control" type="text" placeholder="Max-Högger-Strasse 6, 8048 Zürich" readonly>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="text" name="individualappointment" class="form-control" placeholder="{{this.Page_content.BlockSection.placeholder8}}" 
                              [(ngModel)]="appointmentPost.apartmentType" name="apartmentType" #apartmentType="ngModel">

                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="text" name="company" class="form-control" placeholder="{{this.Page_content.BlockSection.placeholder}}"
                              [(ngModel)]="appointmentPost.companies" name="companies" #companies="ngModel">
                              <div class="help-block with-errors"></div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="text" name="name" class="form-control" placeholder="{{this.Page_content.BlockSection.placeholder1}}*" required="true" data-error="Name is required."
                              [(ngModel)]="appointmentPost.name" name="nameA" #nameA="ngModel">
                              <small *ngIf="(nameA.dirty || nameA.touched) &&  nameA.invalid"
                              class="help-block with-errors text-white">
                              <div *ngIf="nameA.errors.required">
                                  Name field is required.
                              </div>
                          </small>
                             
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="email" name="email" class="form-control" placeholder="{{this.Page_content.BlockSection.placeholder2}} *" required="required" data-error="Valid email is required."
                              [(ngModel)]="appointmentPost.email" name="emailA" #emailA="ngModel">
                              <small *ngIf="(emailA.dirty || emailA.touched) &&  emailA.invalid"
                              class="help-block with-errors text-white">
                              <div *ngIf="emailA.errors.required">
                                  Email field is required.
                              </div>
                          </small>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="tel" name="phone" class="form-control" placeholder="{{this.Page_content.BlockSection.placeholder3}}"
                              [(ngModel)]="appointmentPost.phone" name="phone" #phone="ngModel">
                              <div class="help-block with-errors"></div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="text" name="streetno" class="form-control" placeholder="{{this.Page_content.BlockSection.placeholder4}}"
                              [(ngModel)]="appointmentPost.street" name="street" #street="ngModel">
                              <div class="help-block with-errors"></div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="text" name="postcode" class="form-control" placeholder="{{this.Page_content.BlockSection.placeholder5}}"
                              [(ngModel)]="appointmentPost.postcode" name="postcode" #postcode="ngModel">
                              <div class="help-block with-errors"></div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <textarea name="message" class="form-control" placeholder="{{this.Page_content.BlockSection.placeholder6}} *" rows="3" required data-error="Please,leave us a message."
                              [(ngModel)]="appointmentPost.news" name="newsA" #newsA="ngModel"></textarea>
                              <small *ngIf="(newsA.dirty || newsA.touched) &&  newsA.invalid"
                              class="help-block with-errors text-white">
                              <div *ngIf="newsA.errors.required">
                                  News field is required.
                              </div>
                          </small>
                             
                            </div>
                          </div>
                          <div class="col-md-6"></div>
                          <div class="col-md-6 mb-3">
                            <button type="submit" class="btn btn-blue btn-block btn-send"><small>{{this.Page_content.BlockSection.buttonText2}}</small> <span class="small-icon float-right"><i class="fa fa-arrow-right"></i></span></button>
                          </div>
                        </div>

                      </div>
                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
