import { Component, OnInit } from '@angular/core';
//import{EnService} from './../services/data/en.service';
import{GeService} from './../services/data/ge.service';
@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.css']
})
export class SocialMediaComponent implements OnInit {
  settings :any={imagealign:"right",isfirst:true};
  Page_content :any;
 // constructor(private en :EnService)
 constructor(private ge :GeService)
  {
  }

  ngOnInit(): void {
  //  this.Page_content =this.en.get().SocialMedia;
    this.Page_content =this.ge.get().SocialMedia;
    window.scrollTo(0, 0);
  }
}
