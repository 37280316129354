<!-- Top Bar Start -->
		<div class="bg-light text-dark sm-none">
			<div class="container-xl">
				<div class="row">
					<div class="col-8 col-md-8 mt-1">
						<ul class="mail-txt">
							<li><a href="mailto:info@swisswebart.ch"><i class="fa fa-envelope"></i> info@swisswebart.ch</a></li>
							<li><a href="tel:438839483"><i class="fa fa-phone"></i> +41 43 883 94 83</a></li>
						</ul>
					</div>
					<div class="col-4 col-md-4">
						<ul class="social-icons text-right">
							<li class="social-facebook">
								<a href="https://www.facebook.com/webtrade365" target="blank"><i class="fa fa-facebook-square"></i></a>
							</li>
							<li class="social-google-plus">
								<a href="#" target="blank"><i class="fa fa-google-plus-square"></i></a>
							</li>
							<li class="social-instagram">
								<a href="#" target="blank"><i class="fa fa-instagram"></i></a>
							</li>

						</ul>
					</div>
				</div>
			</div>
		</div><!-- Top Bar End -->