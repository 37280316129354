import { Component, OnInit } from '@angular/core';
import { from } from 'rxjs';
import{EnService} from './../services/data/en.service';
import{GeService} from './../services/data/ge.service';
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  Page_content :any;
  name:string="about";
  settings :any={imagealign:"left",Iscol:"no",isfirst:true,d_mainrow:{d_class:'row row-eq-height',d_col:[{d_class:'col-12 col-md-6'},{d_class:'col-12 col-md-6 align-self-center'}]}};
  settings2 :any={imagealign:"right",Iscol:"yes",isfirst:false,d_mainrow:{d_class:'row row-eq-height',d_col:[{d_class:'col-12 col-md-6 align-self-center'},{d_class:'col-12 col-md-6 d-none d-sm-block d-lg-block'},{d_class:'col-12 col-md-12'},{d_class:'col-12 col-md-12'}]}};
  //constructor(private en :EnService)
  constructor(private ge :GeService)

  { }

  ngOnInit(): void {
    //this.Page_content =this.en.get().About;
    this.Page_content =this.ge.get().About;
    window.scrollTo(0, 0);
  }

}
