<header>
    <app-header-top></app-header-top>
    <app-head></app-head>
    </header>

<div class="col-md-12">
    <h1 class="text-center my-5 py-5" style="line-height:460px;">Coming Soon</h1>
</div>
   

   <footer>
    <app-footer></app-footer>
    </footer><!-- Footer End -->
  