import { Input, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GoogleMap } from '@angular/google-maps';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { contactPostModel } from 'src/app/models/contactModel';
import { FormService } from 'src/app/services/data/FormPost/forms.service';
@Component({
  selector: 'app-full-width-holder',
  templateUrl: './full-width-holder.component.html',
  styleUrls: ['./full-width-holder.component.css']
})


export class FullWidthHolderComponent implements OnInit {
  @Input("modulesettings") ms:any;
  @Input("content") blockcontent:any;

  @ViewChild('contactForm')
  contactForm!: NgForm;
  contactPost:contactPostModel

  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  
  markers=[];
  zoom = 12
  // center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    //mapTypeId: 'hybrid',
    zoomControl: false,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 8,
    center: { lat: 47.393532, lng: 8.488850 },
  }
  addMarker() {
    this.markers.push({
      position: {
        lat: 47.393532,
        lng: 8.488850,
      },
      // label: {
      //   color: 'red',
      //   text: 'Marker label ' + (this.markers.length + 1),
      // },
      title: 'Marker title ' + (this.markers.length + 1),
      options: { animation: google.maps.Animation.DROP },
    })
  }
  constructor(private mail:FormService,private toastr: ToastrService,private router: Router) { 
    this.contactPost=new contactPostModel();
  }

  ngOnInit(): void {
    this.addMarker();
   //console.log("This is the parent data", this.ms.imagealign);

    // navigator.geolocation.getCurrentPosition((position) => {
    //   this.center = {
    //     lat: position.coords.latitude,
    //     lng: position.coords.longitude,
    //   }
    // })

  }
  submit(){
    if (this.contactForm.invalid) {
      this.contactForm.form.markAllAsTouched();
      console.log('invalid');
      //this.scrollTop();
    }
    else {
      console.log('Form Post',this.contactPost);
      this.mail.sendMail(this.contactPost).subscribe(q=>{
        console.log('sendMail',q);
        this.toastr.success("Message Sent Successfully","SUCCESS");
        this.reloadCurrentRoute();
      },err=>{
        this.toastr.error("Oop! Something went wrong","ERROR");
      });
    }


  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
}
