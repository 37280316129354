import { Component, OnInit } from '@angular/core';
import {EnService} from './../services/data/en.service';
import{GeService} from './../services/data/ge.service';
@Component({
  selector: 'app-facebook-marketing1',
  templateUrl: './facebook-marketing1.component.html',
  styleUrls: ['./facebook-marketing1.component.css']
})
export class FacebookMarketing1Component implements OnInit {
  settings :any={imagealign:"right",isfirst:true};
  settings2 :any={imagealign:"left",isfirst:false};
  settings3 :any={imagealign:"right",isfirst:false};
  //constructor(private en :EnService);
  Page_content :any;
  constructor(private ge :GeService)
   { }

  ngOnInit(): void {
    this.Page_content =this.ge.get().Facebookmarketing1;
    window.scrollTo(0, 0);
  }

}
