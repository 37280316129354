<header>
    <app-header-top></app-header-top>
    <app-head></app-head>
    </header>
    <app-flex-width-container [modulesettings]="settings" [content]="Page_content.BlockSection"></app-flex-width-container>
    <app-infoblock-holder [col]="4" [content]="Page_content.Infoblock"></app-infoblock-holder>
    <app-infoblock-holder [col]="4" [content]="Page_content.Infoblock1"></app-infoblock-holder>
      <!-- Footer Start -->
    <footer>
    <app-contact-block></app-contact-block>
    <app-footer></app-footer>
    </footer><!-- Footer End -->
