import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
// const API_ROOT_URL = `${environment.apiUrl}`;
@Injectable({
  providedIn: 'root'
})
export class FormService {

  token: any;
  // httpHeaders: HttpHeaders;
  constructor(private http: HttpClient) {
    this.token = JSON.parse(localStorage.getItem('token')!);
    // this.httpHeaders = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Authorization': this.token
    // });
  }


  sendMail(obj) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization': this.token
    });
    var formData: any = new FormData();

    // formData.append("fname", obj.fname);
    // formData.append("lname", obj.lname);
    // formData.append("email", obj.email);
    // let prms = new HttpParams();

    // prms = prms.append('fname', obj.fname);
    // prms=prms.append('lname',obj.lname);
    // prms=prms.append('email',obj.email)

    return this.http.post<any>(`https://www.swisswebart.ch/mailsnd.php`, obj, {headers: httpHeaders }).pipe(
      map((d) => {
        // You can perform some transformation here
        return d;
      }),
      catchError((err) => {
        // console.error(err);
        throw err;
      })
    );
  }
  genralFormPost(obj) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization': this.token
    });
    var formData: any = new FormData();

    // formData.append("fname", obj.fname);
    // formData.append("lname", obj.lname);
    // formData.append("email", obj.email);
    // let prms = new HttpParams();

    // prms = prms.append('fname', obj.fname);
    // prms=prms.append('lname',obj.lname);
    // prms=prms.append('email',obj.email)

    return this.http.post<any>(`https://www.swisswebart.ch/generalform.php`, obj, {headers: httpHeaders }).pipe(
      map((d) => {
        // You can perform some transformation here
        return d;
      }),
      catchError((err) => {
        // console.error(err);
        throw err;
      })
    );
  }
  AppointmentformPost(obj) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization': this.token
    });
    var formData: any = new FormData();

    // formData.append("fname", obj.fname);
    // formData.append("lname", obj.lname);
    // formData.append("email", obj.email);
    // let prms = new HttpParams();

    // prms = prms.append('fname', obj.fname);
    // prms=prms.append('lname',obj.lname);
    // prms=prms.append('email',obj.email)

    return this.http.post<any>(`https://www.swisswebart.ch/appointment.php`, obj, {headers: httpHeaders }).pipe(
      map((d) => {
        // You can perform some transformation here
        return d;
      }),
      catchError((err) => {
        // console.error(err);
        throw err;
      })
    );
  }
  
}
