import { Component, OnInit } from '@angular/core';
import{EnService} from './../services/data/en.service';
import{GeService} from './../services/data/ge.service';
@Component({
  selector: 'app-web-design',
  templateUrl: './web-design.component.html',
  styleUrls: ['./web-design.component.css']
})
export class WebDesignComponent implements OnInit {
  settings :any={imagealign:"right",isfirst:true,Iscol:"no", d_mainrow:{d_class:'row row-eq-height ',d_col:[{d_class:'col-12 col-md-6 pt-md-5 mt-md-5'},{d_class:'col-12 col-md-6 d-none d-sm-block d-lg-block'}]}};
  settings2 :any={imagealign:"left",isfirst:false,Iscol:"yes",d_mainrow:{d_class:'row row-eq-height',d_col:[{d_class:'col-12 col-md-4'},{d_class:'col-12 col-md-8'},{d_class:'col-12 col-md-6'},{d_class:'col-12 col-md-6'}]}};
  settings3 :any={imagealign:"right",isfirst:false,Iscol:"yes",d_mainrow:{d_class:'row row-eq-height',d_col:[{d_class:'col-12 col-md-8'},{d_class:'col-12 col-md-4 d-none d-sm-block d-lg-block'},{d_class:'col-12 col-md-6'},{d_class:'col-12 col-md-6'}]}};
  settings4 :any={imagealign:"left",isfirst:false,Iscol:"no",d_mainrow:{d_class:'row row-eq-height',d_col:[{d_class:'col-12 col-md-4'},{d_class:'col-12 col-md-8'}]}};
  settings5 :any={imagealign:"",isfirst:false,Iscol:"no",d_mainrow:{d_class:'row row-eq-height',d_col:[{d_class:'col-12 col-md-12 max-auto text-center'}]}};
  settings6 :any={imagealign:"left",isfirst:false,Iscol:"no",d_mainrow:{d_class:'row row-eq-height',d_col:[{d_class:'col-12 col-md-5'},{d_class:'col-12 col-md-7'}]}};
  settings7 :any={imagealign:"right",isfirst:true,Iscol:"no",d_mainrow:{d_class:'row row-eq-height',d_col:[{d_class:'col-12 col-md-5'},{d_class:'col-12 col-md-7 d-none d-sm-block d-lg-block'}]}};
  Page_content :any;
 // constructor(private en :EnService)
  constructor(private ge :GeService)
  {
  }

  ngOnInit(): void {
    //this.Page_content =this.en.get().Webdesign;
    this.Page_content =this.ge.get().Webdesign;
    window.scrollTo(0, 0);
  }

}
