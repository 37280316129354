<header>
    <app-header-top></app-header-top>
    <app-head></app-head>
</header>

<div class="common-section firstblock">
    <div class="container-xl">
        <div class="row">
            <div class="col-md-12">

<h2>Kontakt-Adresse</h2>
<p>Swisswebart<br>
    Trade 365 GmbH<br>
    Max-Högger-Strasse 6<br>
 8048 Zürich</p>
<p>E-Mail: info@swisswebart.ch</p>
<p><span style="font-size: 16px;"> </span></p>
<h2>Handelsregister-Eintrag</h2>
<p>Eingetragener Firmenname: Trade 365 GmbH</p>
<p> Handelsregister Nr: CHE-280.596.411</p>
<p>&nbsp;</p>
<h2>Haftungsausschluss</h2>
<p>Der Autor übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und Vollständigkeit der Informationen.</p>
<p>Haftungsansprüche gegen den Autor wegen Schäden materieller oder immaterieller Art, welche aus dem Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch Missbrauch der Verbindung oder durch technische Störungen entstanden sind, werden ausgeschlossen.</p>
<p>Alle Angebote sind unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne besondere Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.</p>
<p>&nbsp;</p>
<h2>Haftungsausschluss für Links</h2>
<p>Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs. Es wird jegliche Verantwortung für solche Webseiten abgelehnt. Der Zugriff und die Nutzung solcher Webseiten erfolgen auf eigene Gefahr des jeweiligen Nutzers.</p>
<p>&nbsp;</p>
<h2>Urheberrechte</h2>
<p>Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf dieser Website, gehören ausschliesslich <strong>der Firma Trade 365 GmbH</strong> oder den speziell genannten Rechteinhabern. Für die Reproduktion jeglicher Elemente ist die schriftliche Zustimmung des Urheberrechtsträgers im Voraus einzuholen.</p>
<p>&nbsp;</p>
<p>Quelle: <a href="https://www.swissanwalt.ch/impressum-generator.aspx" target="_blank" rel="noopener noreferrer">Impressum-Generator von SwissAnwalt</a></p>
            </div>
        </div>
    </div>
</div>
<!-- Footer Start -->
<footer>
    <app-contact-block></app-contact-block>
    <app-footer></app-footer>
</footer>