import { Component, OnInit } from '@angular/core';
import{GeService} from '../../services/data/ge.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  Page_content :any;
  constructor(private ge :GeService) { }

  ngOnInit(): void {
    this.Page_content =this.ge.get().footerblock;
  }

}
