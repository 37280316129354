<header>
    <app-header-top></app-header-top>
    <app-head></app-head>
    </header>
    <app-full-width-holder [modulesettings]="settings" [content]="Page_content.BlockSection"></app-full-width-holder>
  
    <app-infoblock-holder [col]="4" [content]="Page_content.Infoblock"></app-infoblock-holder>
    <app-full-width-holder [modulesettings]="settings2" [content]="Page_content.BlockSection1"></app-full-width-holder>
    <app-full-width-holder [modulesettings]="settings3" [content]="Page_content.BlockSection2"></app-full-width-holder>
    <app-full-width-holder [modulesettings]="settings4" [content]="Page_content.BlockSection3"></app-full-width-holder>
    <app-full-width-holder [modulesettings]="settings5" [content]="Page_content.BlockSection4"></app-full-width-holder>
      <!-- Footer Start -->
      <footer>
        <app-contact-block></app-contact-block>
        <app-footer></app-footer>
        </footer><!-- Footer End -->
