import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
//import {EnService} from './../services/data/en.service';
import{GeService} from './../services/data/ge.service';
declare let $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  Page_content :any;
  //settings :any={imagealign:"right",isfirst:false};
  settings :any={imagealign:"right",Iscol:"yes",isfirst:false,d_mainrow:{d_class:'row row-eq-height',d_col:[{d_class:'text-blue pb-4 col-12 col-md-4'},{d_class:'text-blue pb-4 col-12 col-md-4'}]}};

  images:string[]=["assets/dist/images/harley-davidson-logo.svg","assets/dist/images/harley-davidson-logo.svg"];
 //constructor(private en :EnService)
 constructor(private ge :GeService)
  {
  }

  ngOnInit(): void {
    //this.Page_content =this.en.get().Home;
    this.Page_content =this.ge.get().Home;
    window.scrollTo(0, 0);
  }

  ngAfterViewInit() {
    $('#logo-carousel .owl-carousel').owlCarousel({
      loop:true,
      margin: 40,
      nav:false,
      dots:false,
      autoplay:true,
      autoplayHoverPause:true,
      responsive:{
        0:{
          items:3,
          margin: 40,
        },
        600:{
          items:4
        },
        1000:{
          items:8
        }
      }
    });

    //============ Blog Carousel ====================
    $('#blog-carousel .owl-carousel').owlCarousel({
      loop:true,
      margin:24,
      nav:false,
      dots:false,
      autoplay:true,
      autoplayHoverPause:true,
      responsive:{
        0:{
          items:1
        },
        600:{
          items:2
        },
        1000:{
          items:4
        }
      }
    });
  }
}
